import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../button/Button';

import { Container } from './Card.styled';

const Card = ({ data, setIsModalOpen, setCardId }) => {
  const { t } = useTranslation();
  const handleButtonClick = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  return (
    <Container data-aos='fade-up'>
      <Container.Img src={data?.attachments[0]?.path} alt='card-img' />
      <Container.SubTitle className='descr'>{data?.name}</Container.SubTitle>
      <Container.Descr className='descr'>
        {data?.description?.length > 70 ? (
          <>{data?.description?.slice(0, 70)}...</>
        ) : (
          data?.description
        )}
      </Container.Descr>
      <div className='button-container'>
        <Button
          onClick={() => {
            handleButtonClick();
            setCardId(data?.id);
          }}
          type='outlined'
        >
          {t('Get In Touch')}
        </Button>
      </div>
    </Container>
  );
};

export default Card;

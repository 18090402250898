import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LanguageDropDownComponent from '../../components/language/Language';
import SocialMedia from '../../components/socialMedia/SocialMedia';
import Button from '../../components/button/Button';

import { mainRoutes } from '../../routes/utils/mainRoutes';

import logo from '../../assets/icons/sbcapital 2.svg';

import { Container, Flex, NavItems } from './Navbar.styled';

const Navbar = () => {
  const { t } = useTranslation();
  const [isBarOpen, setIsBarOpen] = useState(false);

  return (
    <Container>
      <Link to={'/'}>
        <Container.Logo src={logo} alt='logo' />
      </Link>
      <NavItems open={isBarOpen}>
        <NavItems.Cancel onClick={() => setIsBarOpen(false)} />
        {mainRoutes.map((route) => {
          return (
            route?.isNavbar && (
              <NavItems.Item
                onClick={() => setIsBarOpen(false)}
                className='descr'
                key={route?.id}
                to={route?.path}
              >
                {t(route?.title)}
              </NavItems.Item>
            )
          );
        })}

        <div className='language'>
          <LanguageDropDownComponent />
        </div>

        <a className='btn' href='#contact'>
          <Button type='primary'>{t(`Get In Touch`)}</Button>
        </a>

        <div className='wrap'>
          <SocialMedia component='navbar' />
          <Flex>
            <Flex.Phone />
            <Flex.Text className='descr'>+97 152 891 29 18</Flex.Text>
          </Flex>
        </div>
      </NavItems>
      <div className='responsive-menu'>
        <LanguageDropDownComponent />
        <Container.Menu onClick={() => setIsBarOpen(true)} />
      </div>
    </Container>
  );
};

export default Navbar;

import styled from 'styled-components';

const Container = styled.div`
  background: var(--bg-dark-grey-color);
  padding: 12px 12px 24px 12px;
  display: flex;
  flex-direction: column;

  .button-container {
    margin-top: auto;
  }
`;

Container.Img = styled.img`
  width: 100%;
  height: 60%;
`;

Container.SubTitle = styled.h3`
  color: var(--txt-white-color);
  margin-top: 16px;
  text-transform: uppercase;
`;

Container.Descr = styled.p`
  color: var(--txt-grey-color);
  margin: 8px 0px 24px 0px;
  font-family: var(--txt-inter-font);
`;

export { Container };

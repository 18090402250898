import React, { memo } from 'react';

import { Container } from './SocialMedia.styled';

const SocialMedia = ({ component }) => {
  return (
    <Container component={component}>
      <Container.Phone />
      <Container.Telegram />
      <Container.Facebook />
      <Container.Instagram />
    </Container>
  );
};

export default memo(SocialMedia);
